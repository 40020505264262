// src/components/Balloons.js
import React from 'react';
import './Balloons.css';

const Balloons = () => {
    return (
        <div className="balloons-background">
            <div className="balloon"></div>
            <div className="balloon"></div>
            <div className="balloon"></div>
            <div className="balloon"></div>
            <div className="balloon"></div>
            <div className="balloon"></div>
            <div className="balloon"></div>
            <div className="balloon"></div>
            <div className="balloon"></div>
            <div className="balloon"></div>
        </div>
    );
};

export default Balloons;
