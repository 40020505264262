// src/components/AdminPage.js
import React, { useState, useEffect } from 'react';
import { db, collection } from '../firebaseConfig';
import { getDocs, addDoc, updateDoc, deleteDoc, doc } from "firebase/firestore";
import { toast } from 'react-toastify';
import { FaPlusCircle, FaSave, FaEdit, FaTrash } from 'react-icons/fa';
import './AdminPage.css';

const AdminPage = () => {
    const [items, setItems] = useState([]);
    const [newItemName, setNewItemName] = useState('');
    const [newItemUrl, setNewItemUrl] = useState('');
    const [editingItem, setEditingItem] = useState(null);
    const [editItemName, setEditItemName] = useState('');
    const [editItemUrl, setEditItemUrl] = useState('');

    useEffect(() => {
        const fetchItems = async () => {
            const itemsCollection = collection(db, 'items');
            const itemsSnapshot = await getDocs(itemsCollection);
            setItems(itemsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        };

        fetchItems();
    }, []);

    const addItem = async () => {
        if (newItemName.trim() === '') {
            toast.error('Le nom de l\'objet ne peut pas être vide.');
            return;
        }

        try {
            const itemsCollection = collection(db, 'items');
            await addDoc(itemsCollection, { name: newItemName, url: newItemUrl, purchasedBy: null });
            setNewItemName('');
            setNewItemUrl('');
            const itemsSnapshot = await getDocs(itemsCollection);
            setItems(itemsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
            toast.success('Article ajouté avec succès !');
        } catch (error) {
            toast.error('Une erreur est survenue lors de l\'ajout de l\'article.');
        }
    };

    const updateItem = async (id) => {
        if (editItemName.trim() === '') {
            toast.error('Le nom de l\'objet ne peut pas être vide.');
            return;
        }

        try {
            const itemDoc = doc(db, 'items', id);
            await updateDoc(itemDoc, { name: editItemName, url: editItemUrl });
            setEditingItem(null);
            setEditItemName('');
            setEditItemUrl('');
            const itemsSnapshot = await getDocs(collection(db, 'items'));
            setItems(itemsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
            toast.success('Article mis à jour avec succès !');
        } catch (error) {
            toast.error('Une erreur est survenue lors de la mise à jour de l\'article.');
        }
    };

    const deleteItem = async (id) => {
        try {
            const itemDoc = doc(db, 'items', id);
            await deleteDoc(itemDoc);
            const itemsSnapshot = await getDocs(collection(db, 'items'));
            setItems(itemsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
            toast.success('Article supprimé avec succès !');
        } catch (error) {
            toast.error('Une erreur est survenue lors de la suppression de l\'article.');
        }
    };

    return (
        <div>
            <h2>Gestion des objets</h2>
            <div className="input-container">
                <input
                    type="text"
                    placeholder="Nom de l'objet"
                    value={newItemName}
                    onChange={(e) => setNewItemName(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Lien de l'objet (URL)"
                    value={newItemUrl}
                    onChange={(e) => setNewItemUrl(e.target.value)}
                />
                <button onClick={addItem}>
                    <FaPlusCircle /> Ajouter
                </button>
            </div>
            <ul className="item-list">
                {items.map(item => (
                    <li key={item.id} className="item">
                        {editingItem === item.id ? (
                            <>
                                <input
                                    type="text"
                                    value={editItemName}
                                    onChange={(e) => setEditItemName(e.target.value)}
                                />
                                <input
                                    type="text"
                                    placeholder="Lien de l'objet (URL)"
                                    value={editItemUrl}
                                    onChange={(e) => setEditItemUrl(e.target.value)}
                                />
                                <div className="item-buttons">
                                    <button onClick={() => updateItem(item.id)}>
                                        <FaSave /> Enregistrer
                                    </button>
                                    <button onClick={() => setEditingItem(null)}>
                                        <FaEdit /> Annuler
                                    </button>
                                </div>
                            </>
                        ) : (
                            <>
                                <span>{item.name}</span>
                                {item.url && <a href={item.url} target="_blank" rel="noopener noreferrer">Lien</a>}
                                <div className="item-buttons">
                                    <button onClick={() => {
                                        setEditingItem(item.id);
                                        setEditItemName(item.name);
                                        setEditItemUrl(item.url);
                                    }}>
                                        <FaEdit />
                                    </button>
                                    <button onClick={() => deleteItem(item.id)}>
                                        <FaTrash />
                                    </button>
                                </div>
                            </>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default AdminPage;
