// src/components/ThankYouModal.js
import React from 'react';
import Modal from 'react-modal';
import './ThankYouModal.css';
import bgThankYou from './thankyou.JPG';

Modal.setAppElement('#root');

const ThankYouModal = ({ isOpen, onRequestClose }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="Modal"
            overlayClassName="Overlay"
        >
            <div className="modal-content">
                <img src={bgThankYou} alt="Thank you" className="modal-image" />
                <h2>Merci à tous nos proches</h2>
                <p>
                    Nous remercions tous nos proches de nous aider. Le minimum est vraiment beaucoup pour nous.
                    Nous avons hâte de la naissance avec vous tous. Merci encore. 👶🍼
                </p>
                <p>Tamara et Fabrice</p>
                <button onClick={onRequestClose} className="close-button">Fermer</button>
            </div>
        </Modal>
    );
};

export default ThankYouModal;
